export const regions: { [key: number]: { abbreviation: 'NTSC' | 'PAL' | 'NTSC-J'; name: string; } } = {
  1: {
    abbreviation: 'NTSC',
    name: "United States"
  },
  2: {
    abbreviation: 'PAL',
    name: "United Kingdom"
  },
  6: {
    abbreviation: 'NTSC-J',
    name: "Japan"
  },
  11: {
    abbreviation: 'PAL',
    name: "Australia"
  }
}
