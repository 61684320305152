import React, { useState } from 'react';

import { platforms } from './platforms';
import { regions } from './regions';

// const conditionPropertiesMap = {
//   isSealed: {
//     icon: '🎁',
//     title: 'Sealed',
//   },
//   isBrandNew: {
//     icon: '✨',
//     title: 'Brand new',
//   },
//   includesGame: {
//     icon: '💿',
//     title: 'Includes game',
//   },
//   includesBox: {
//     icon: '📦',
//     title: 'Includes box',
//   },
//   includesManual: {
//     icon: '📔',
//     title: 'Includes manual',
//   },
//   includesInserts: {
//     icon: '🗺',
//     title: 'Includes inserts',
//   },
// }


export default function GameListItem({ game }: { game: games.Game }) {
  const [hideImg, setHideImg] = useState(false);
  const boxart = game.game.boxart;

  const generateTitleAttr = (releaseName: string) => {
    const platform = platforms[game.release.platformId].abbreviation;
    const regionName = regions[game.release.regionId].name;

    return `${releaseName} (${platform}) (${regionName})`;
  }

  const className = "rounded-md shadow-md bg-white p-2 flex flex-col";

  return (
    <div
      className={className}
      title={generateTitleAttr(game.release.name)}>
      <header className="mb-2">
        <div className="flex items-center justify-between">
          <h2 className="text-sm leading-4 font-medium truncate mr-2">{game.release.name}</h2>
          <svg
              className="stroke-current stroke-1 text-green-600"
              style={{ minWidth: 14, minHeight: 14, }}
              width="14" height="14"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
        </div>
        <div className="flex items-center">
          <div className="flex items-center mr-2">
            <svg
              className="stroke-current stroke-1 opacity-50"
              style={{ minWidth: 12, minHeight: 12, }}
              width="12" height="12"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
            </svg>
            <h3 className="text-xs font-medium text-black text-opacity-50 truncate">{platforms[game.release.platformId].abbreviation}</h3>
          </div>

          <div className="flex items-center mr-2">
            <svg
              className="stroke-current stroke-1 opacity-50"
              style={{ minWidth: 12, minHeight: 12, }}
              width="12" height="12"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg>
            <h5 className="text-xs font-medium text-black text-opacity-50 truncate">{regions[game.release.regionId].abbreviation}</h5>
          </div>
        </div>
      </header>

      { hideImg ? <div className="bg-gray-100 overflow-hidden relative h-48"></div> : (
        <div className="bg-gray-100 overflow-hidden relative h-48">
          <img
            className="object-scale-down h-48 w-full z-20 relative"
            src={boxart}
            alt={game.game.name}
            onError={e => setHideImg(true)} />
          <div className="absolute top-0 right-0 bottom-0 left-0" style={{ backgroundImage: `url('${boxart}')`, backgroundSize: 'cover', backgroundPosition: 'center', filter: "blur(30px)" }}></div>
        </div>
      )}
    </div>
  )
}
