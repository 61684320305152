import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { GAME_DISPLAY_LIMIT, SEARCH_DEBOUNCE_DELAY } from './config';

import GameListItem from './GameListItem';
import useDebounce from './useDebounce';

import './index.css';

export default function App() {
  const [games, setGames] = useState<games.Game[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  
  const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_DEBOUNCE_DELAY);
  const prevDebouncedSearchQueryRef = useRef<string | undefined>();

  useEffect(() => {
    prevDebouncedSearchQueryRef.current = debouncedSearchQuery;
  })
  
  const prevDebouncedSearchQuery = prevDebouncedSearchQueryRef.current;

  useEffect(() => {
    const loadGames = async () => {
      setLoading(true);

      const { games: newGames, meta } = await fetchGames({ offset, query });

      if (offset === 0) {
        setGames([ ...newGames ]);
      } else {
        setGames(prevGames => [ ...prevGames, ...newGames ]);
      }

      setLoading(false);
      setOffset(meta.offset);
      setHasNextPage(meta.total >= (meta.limit + meta.offset));
    }

    loadGames();
  }, [offset, query])

  useEffect(() => {
    // Determine whether or not the search query has changed
    const newSearch =
      prevDebouncedSearchQuery !== debouncedSearchQuery ||
      debouncedSearchQuery !== null;
      
    if (newSearch) {
      setOffset(0);
      setQuery(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery])

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    // Return early if loading is in progress or if a next page does not exist
    if (loading || !hasNextPage) {
      return;
    }

    if (scrollHeight - scrollTop === clientHeight) {
      setOffset(prevOffset => prevOffset + GAME_DISPLAY_LIMIT);
    }
  }

  return (
    <div className="flex flex-col h-screen bg-gray-200">
      <nav className="flex flex-none items-center bg-white py-2 px-4 shadow-lg bg-gradient-to-br from-purple-500 to-indigo-500">
        <form className="relative flex-grow">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
          </svg>
          <input
            className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 w-full text-md text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
            type="text"
            onChange={e => setSearchQuery(e.target.value)}
            aria-label="Filter games"
            placeholder="Filter games" />
        </form>
      </nav>

      { loading && <div>Loading...</div> }
      <div
        className="overflow-auto px-4 py-6 bg-gray-200"
        onScroll={e => handleScroll(e)}>
        
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {(games as games.Game[]).map((g, i) =>
            <GameListItem key={i} game={g}></GameListItem>
          )}
        </div>
      </div>
    </div>
  );
}

function fetchGames(params: {limit?: number, offset?: number, query?: string} = {}): Promise<games.GamesResponse> {
  return axios.get<games.GamesResponse>(process.env.API_PATH as string, {
      params: {
        limit: GAME_DISPLAY_LIMIT,
        ...params.offset && { offset: params.offset },
        ...params.query && { q: params.query },
      }
    })
    .then(res => res.data);
}

