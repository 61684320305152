export const platforms: {[key: number]: { abbreviation: string; name: string; }} = {
  1: {
    abbreviation: 'AMI',
    name: 'Amiga'
  },
  3: {
    abbreviation: 'GB',
    name: 'Game Boy'
  },
  4: {
    abbreviation: 'GBA',
    name: 'Game Boy Advance'
  },
  5: {
    abbreviation: 'GG',
    name: 'Game Gear'
  },
  6: {
    abbreviation: 'GEN',
    name: 'Genesis'
  },
  7: {
    abbreviation: 'LYNX',
    name: 'Atari Lynx'
  },
  8: {
    abbreviation: 'SMS',
    name: 'Sega Master System'
  },
  9: {
    abbreviation: 'SNES',
    name: 'Super Nintendo Entertainment System'
  },
  11: {
    abbreviation: 'CPC',
    name: 'Amstrad CPC'
  },
  12: {
    abbreviation: 'APL2',
    name: 'Apple II'
  },
  13: {
    abbreviation: 'AST',
    name: 'Atari ST'
  },
  14: {
    abbreviation: 'C64',
    name: 'Commodore 64'
  },
  15: {
    abbreviation: 'MSX',
    name: 'MSX'
  },
  16: {
    abbreviation: 'SPEC',
    name: 'ZX Spectrum'
  },
  17: {
    abbreviation: 'MAC',
    name: 'Mac'
  },
  18: {
    abbreviation: 'PSP',
    name: 'PlayStation Portable'
  },
  19: {
    abbreviation: 'PS2',
    name: 'PlayStation 2'
  },
  20: {
    abbreviation: 'X360',
    name: 'Xbox 360'
  },
  21: {
    abbreviation: 'NES',
    name: 'Nintendo Entertainment System'
  },
  22: {
    abbreviation: 'PS1',
    name: 'PlayStation'
  },
  23: {
    abbreviation: 'GCN',
    name: 'GameCube'
  },
  24: {
    abbreviation: 'A800',
    name: 'Atari 8-bit'
  },
  25: {
    abbreviation: 'NEO',
    name: 'Neo Geo'
  },
  26: {
    abbreviation: '3DO',
    name: '3DO'
  },
  27: {
    abbreviation: 'CDI',
    name: 'CD-i'
  },
  28: {
    abbreviation: 'JAG',
    name: 'Jaguar'
  },
  29: {
    abbreviation: 'SCD',
    name: 'Sega CD'
  },
  30: {
    abbreviation: 'VC20',
    name: 'VIC-20'
  },
  31: {
    abbreviation: '32X',
    name: 'Sega 32X'
  },
  32: {
    abbreviation: 'XBOX',
    name: 'Xbox'
  },
  34: {
    abbreviation: 'NGE',
    name: 'N-Gage'
  },
  35: {
    abbreviation: 'PS3',
    name: 'PlayStation 3'
  },
  36: {
    abbreviation: 'Wii',
    name: 'Wii'
  },
  37: {
    abbreviation: 'DC',
    name: 'Dreamcast'
  },
  38: {
    abbreviation: 'A2GS',
    name: 'Apple IIgs'
  },
  39: {
    abbreviation: 'CD32',
    name: 'Amiga CD32'
  },
  40: {
    abbreviation: '2600',
    name: 'Atari 2600'
  },
  42: {
    abbreviation: 'SAT',
    name: 'Saturn'
  },
  43: {
    abbreviation: 'N64',
    name: 'Nintendo 64'
  },
  47: {
    abbreviation: 'CVIS',
    name: 'ColecoVision'
  },
  48: {
    abbreviation: 'TI99',
    name: 'TI-99/4A'
  },
  51: {
    abbreviation: 'INTV',
    name: 'Intellivision'
  },
  52: {
    abbreviation: 'DS',
    name: 'Nintendo DS'
  },
  53: {
    abbreviation: 'TGCD',
    name: 'TurboGrafx-CD'
  },
  54: {
    abbreviation: 'WSC',
    name: 'WonderSwan Color'
  },
  55: {
    abbreviation: 'TG16',
    name: 'TurboGrafx-16'
  },
  57: {
    abbreviation: 'GBC',
    name: 'Game Boy Color'
  },
  58: {
    abbreviation: 'C128',
    name: 'Commodore 128'
  },
  59: {
    abbreviation: 'NGCD',
    name: 'Neo Geo CD'
  },
  60: {
    abbreviation: 'ODY2',
    name: 'Odyssey 2'
  },
  61: {
    abbreviation: 'DRAG',
    name: 'Dragon 32/64'
  },
  62: {
    abbreviation: 'CBM',
    name: 'Commodore PET/CBM'
  },
  63: {
    abbreviation: 'TRS8',
    name: 'TRS-80'
  },
  64: {
    abbreviation: 'ZOD',
    name: 'Zodiac'
  },
  65: {
    abbreviation: 'WSW',
    name: 'WonderSwan'
  },
  66: {
    abbreviation: 'CHANF',
    name: 'Channel F'
  },
  67: {
    abbreviation: '5200',
    name: 'Atari 5200'
  },
  68: {
    abbreviation: 'COCO',
    name: 'TRS-80 CoCo'
  },
  70: {
    abbreviation: '7800',
    name: 'Atari 7800'
  },
  72: {
    abbreviation: 'IPOD',
    name: 'iPod'
  },
  74: {
    abbreviation: 'ODY',
    name: 'Odyssey'
  },
  75: {
    abbreviation: 'PCFX',
    name: 'PC-FX'
  },
  76: {
    abbreviation: 'VECT',
    name: 'Vectrex'
  },
  77: {
    abbreviation: 'GCOM',
    name: 'Game.Com'
  },
  78: {
    abbreviation: 'GIZ',
    name: 'Gizmondo'
  },
  79: {
    abbreviation: 'VBOY',
    name: 'Virtual Boy'
  },
  80: {
    abbreviation: 'NGP',
    name: 'Neo Geo Pocket'
  },
  81: {
    abbreviation: 'NGPC',
    name: 'Neo Geo Pocket Color'
  },
  82: {
    abbreviation: 'VSML',
    name: 'V.Smile'
  },
  83: {
    abbreviation: 'PIN',
    name: 'Pinball'
  },
  84: {
    abbreviation: 'ARC',
    name: 'Arcade'
  },
  85: {
    abbreviation: 'NUON',
    name: 'NUON'
  },
  86: {
    abbreviation: 'XBGS',
    name: 'Xbox 360 Games Store'
  },
  87: {
    abbreviation: 'WSHP',
    name: 'Wii Shop'
  },
  88: {
    abbreviation: 'PS3N',
    name: 'PlayStation Network (PS3)'
  },
  89: {
    abbreviation: 'LEAP',
    name: 'Leapster'
  },
  90: {
    abbreviation: 'MVIS',
    name: 'Microvision'
  },
  91: {
    abbreviation: 'FDS',
    name: 'Famicom Disk System'
  },
  92: {
    abbreviation: 'LACT',
    name: 'Pioneer LaserActive'
  },
  93: {
    abbreviation: 'AVIS',
    name: 'Adventure Vision'
  },
  94: {
    abbreviation: 'PC',
    name: 'PC'
  },
  95: {
    abbreviation: 'X68K',
    name: 'Sharp X68000'
  },
  96: {
    abbreviation: 'IPHN',
    name: 'iPhone'
  },
  98: {
    abbreviation: 'BS-X',
    name: 'Satellaview'
  },
  99: {
    abbreviation: 'A2K1',
    name: 'Arcadia 2001'
  },
  100: {
    abbreviation: 'AQUA',
    name: 'Aquarius'
  },
  101: {
    abbreviation: '64DD',
    name: 'Nintendo 64DD'
  },
  102: {
    abbreviation: 'PIPN',
    name: 'Pippin'
  },
  103: {
    abbreviation: 'RZON',
    name: 'R-Zone'
  },
  104: {
    abbreviation: 'HSCN',
    name: 'HyperScan'
  },
  105: {
    abbreviation: 'GWAV',
    name: 'Game Wave'
  },
  106: {
    abbreviation: 'DSI',
    name: 'DSiWare'
  },
  107: {
    abbreviation: 'HALC',
    name: 'RDI Halcyon'
  },
  108: {
    abbreviation: 'FMT',
    name: 'FM Towns'
  },
  109: {
    abbreviation: 'PC88',
    name: 'NEC PC-8801'
  },
  110: {
    abbreviation: 'BBCM',
    name: 'BBC Micro'
  },
  111: {
    abbreviation: 'PLATO',
    name: 'PLATO'
  },
  112: {
    abbreviation: 'PC98',
    name: 'NEC PC-9801'
  },
  113: {
    abbreviation: 'X1',
    name: 'Sharp X1'
  },
  114: {
    abbreviation: 'FM7',
    name: 'FM-7'
  },
  115: {
    abbreviation: '6001',
    name: 'NEC PC-6001'
  },
  116: {
    abbreviation: 'PSPN',
    name: 'PlayStation Network (PSP)'
  },
  117: {
    abbreviation: '3DS',
    name: 'Nintendo 3DS'
  },
  118: {
    abbreviation: 'PICO',
    name: 'Sega Pico'
  },
  119: {
    abbreviation: 'SGFX',
    name: 'SuperGrafx'
  },
  120: {
    abbreviation: 'BAST',
    name: 'Bally Astrocade'
  },
  121: {
    abbreviation: 'IPAD',
    name: 'iPad'
  },
  122: {
    abbreviation: 'ZBO',
    name: 'Zeebo'
  },
  123: {
    abbreviation: 'ANDR',
    name: 'Android'
  },
  124: {
    abbreviation: 'WP',
    name: 'Windows Phone'
  },
  125: {
    abbreviation: 'ACRN',
    name: 'Acorn Archimedes'
  },
  126: {
    abbreviation: 'LOOP',
    name: 'Casio Loopy'
  },
  127: {
    abbreviation: 'PDIA',
    name: 'Bandai Playdia'
  },
  128: {
    abbreviation: 'MZ',
    name: 'Sharp MZ'
  },
  129: {
    abbreviation: 'VITA',
    name: 'PlayStation Vita'
  },
  131: {
    abbreviation: 'RCA2',
    name: 'RCA Studio II'
  },
  132: {
    abbreviation: 'XAVX',
    name: 'XaviXPORT'
  },
  133: {
    abbreviation: 'GP32',
    name: 'GamePark 32'
  },
  134: {
    abbreviation: 'PMIN',
    name: 'Pokémon mini'
  },
  135: {
    abbreviation: 'CASV',
    name: 'Epoch Cassette Vision'
  },
  136: {
    abbreviation: 'SCV',
    name: 'Super Cassette Vision'
  },
  137: {
    abbreviation: 'DUCK',
    name: 'Mega Duck'
  },
  138: {
    abbreviation: '3DSE',
    name: 'Nintendo 3DS eShop'
  },
  139: {
    abbreviation: 'WiiU',
    name: 'Wii U'
  },
  140: {
    abbreviation: 'BROW',
    name: 'Browser'
  },
  141: {
    abbreviation: 'SG1K',
    name: 'Sega SG-1000'
  },
  142: {
    abbreviation: 'CDTV',
    name: 'Commodore CDTV'
  },
  143: {
    abbreviation: 'PSNV',
    name: 'PlayStation Network (Vita)'
  },
  144: {
    abbreviation: 'DIDJ',
    name: 'Leapfrog Didj'
  },
  145: {
    abbreviation: 'XONE',
    name: 'Xbox One'
  },
  146: {
    abbreviation: 'PS4',
    name: 'PlayStation 4'
  },
  147: {
    abbreviation: 'SVIS',
    name: 'Watara Supervision'
  },
  148: {
    abbreviation: 'AMAX',
    name: 'Action Max'
  },
  149: {
    abbreviation: 'PV1K',
    name: 'Casio PV-1000'
  },
  150: {
    abbreviation: 'C16',
    name: 'Commodore 16'
  },
  151: {
    abbreviation: 'ACAN',
    name: `Super A'Can`
  },
  152: {
    abbreviation: 'LIN',
    name: 'Linux'
  },
  153: {
    abbreviation: 'VIS',
    name: 'Memorex MD 2500 VIS'
  },
  154: {
    abbreviation: 'OUYA',
    name: 'Ouya'
  },
  155: {
    abbreviation: 'FIRE',
    name: 'Amazon Fire TV'
  },
  156: {
    abbreviation: 'N3DS',
    name: 'New Nintendo 3DS'
  },
  157: {
    abbreviation: 'NSW',
    name: 'Nintendo Switch'
  },
  158: {
    abbreviation: 'HGM',
    name: 'Game Master'
  },
  159: {
    abbreviation: 'APTV',
    name: 'Apple TV'
  },
  160: {
    abbreviation: 'SMC7',
    name: 'Sony SMC-777'
  },
  162: {
    abbreviation: 'COUP',
    name: 'SAM Coupé'
  },
  163: {
    abbreviation: 'VMIV',
    name: 'View-Master Interactive Vision'
  },
  164: {
    abbreviation: 'TF1',
    name: 'Fuze Tomahawk F1'
  },
  165: {
    abbreviation: 'TUT',
    name: 'Tomy Tutor'
  },
  166: {
    abbreviation: 'GMT',
    name: 'Gamate'
  },
  168: {
    abbreviation: 'MBEE',
    name: 'MicroBee'
  },
  169: {
    abbreviation: 'VSOC',
    name: 'VTech Socrates'
  },
  170: {
    abbreviation: 'ABC',
    name: 'Luxor ABC80'
  },
  171: {
    abbreviation: 'JCD',
    name: 'Jaguar CD'
  },
  172: {
    abbreviation: 'ALXA',
    name: 'Amazon Alexa'
  },
  173: {
    abbreviation: 'ML1',
    name: 'Magic Leap One'
  },
  174: {
    abbreviation: 'BNA',
    name: 'Advanced Pico Beena'
  }
}